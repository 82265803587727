@import "@/assets/constants/index.scss";
.subject-tabs-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .code-buttons-panel {
    float: right;

    span {
      margin-right: 16px;
    }

    button {
      width: 204px;
      height: 32px;
    }
  }
}
